import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ArticleResult } from '../data/ArticleFragment'
import { formatDate } from '../utilities/formatDate'
import { getReadingTime } from '../utilities/getReadingTime'
import { LinkChildren } from './LinkChildren'
import styles from './TileBanner.module.sass'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type TileBannerProps = {
	article: ArticleResult
}

export const TileBanner: FunctionComponent<TileBannerProps> = ({ article }) => {
	const translation = useTranslate()

	return (
		<div className={styles.wrapper}>
			<LinkChildren key={article.id} type="internal" link={article.link} className={styles.link}>
				<div className={styles.item}>
					<div className={styles.imageWrapper}>
						{article.tilePhoto ? (
							<Image
								className={styles.image}
								src={article.tilePhoto.url}
								fill
								quality={75}
								alt={article.tilePhoto.alt ?? ''}
								sizes="(min-width: 30rem) 30vw, 100vw)"
							/>
						) : article.coverPhoto ? (
							<Image
								className={styles.image}
								src={article.coverPhoto.url}
								fill
								quality={75}
								alt={article.coverPhoto.alt ?? ''}
								sizes="(min-width: 30rem) 30vw, 100vw)"
							/>
						) : null}
					</div>
					<div className={styles.content}>
						<div className={styles.contentTop}>
							{article.publishedAt && <div className={styles.publishedAt}>{formatDate('cs', article.publishedAt)}</div>}
							{article.readingTime && article.readingTime > 0 && (
								<div className={styles.readingTime}>
									{article.readingTime} {translation(`${getReadingTime(article.readingTime)}`)}
								</div>
							)}
						</div>
						{article.title && <h3 className={styles.contentTitle}>{article.title}</h3>}
						{article.lead && <p className={styles.contentLead}>{article.lead}</p>}
					</div>
				</div>
			</LinkChildren>
		</div>
	)
}
